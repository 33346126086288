import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { map, mergeMap, Observable, of } from 'rxjs';
import { HereAutocompleteResponseModel, HereGeocodeModel, NearestValue } from '../models';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    constructor(private httpClient: HttpClient) {}

    getLookup(id: string): Observable<HereGeocodeModel> {
        const params = {
            id: id,
        };
        return this.httpClient.addHeremapsLookup().get<HereGeocodeModel>('', { params });
        // #FIX for missing postal codes
        // .pipe(
        //     switchMap((item) => {
        //         if (item.address.postalCode === undefined) {
        //             // return of(item);
        //             if (environment.integrations.hereMaps.v2) {
        //                 return this.closestv2('', item.address.city, item.address.countryCode, item.position.lat, item.position.lng);
        //             } else {
        //                 return this.closest(item.title, item.address.city, item.address.countryCode, item.position.lat, item.position.lng);
        //             }
        //         }
        //         return of(item);
        //     }),
        // );
    }

    closest(term: string, city: string, countryCode: string, lat: number, lng: number): Observable<HereGeocodeModel> {
        const params: Record<string, string> = {
            q: term || city,
            in: `countryCode:${countryCode}`,
            limit: '10',
            show: 'details',
            at: [lat, lng].join(','),
            resultTypes: 'street,houseNumber',
        };
        return this.httpClient
            .addHeremapsAutosuggest()
            .skipErrorHandler()
            .get<HereAutocompleteResponseModel>('', { params })
            .pipe(
                map((response) => {
                    const items = [
                        ...response.items
                            .filter((item) => ['street', 'houseNumber'].includes(item.resultType))
                            .filter((item) => item.address.city === city)
                            .filter((item) => item.address.postalCode),
                    ];
                    if (items.length > 0) {
                        return items[0];
                    }
                    throw new HttpErrorResponse({ error: 'not-found', status: 404 });
                }),
            );
    }

    closestv2(term: string, city: string, countryCode: string, lat: number, lng: number): Observable<HereGeocodeModel> {
        const params: Record<string, string> = {
            q: term || city,
            in: `countryCode:${countryCode}`,
            limit: '100',
            show: 'details',
            at: [lat, lng].join(','),
        };

        return this.httpClient
            .addHeremapsAutosuggest()
            .skipErrorHandler()
            .get<HereAutocompleteResponseModel>('', { params })
            .pipe(
                map((response) => {
                    const items = [...response.items.filter((item) => item.address.city === city).filter((item) => item.address.postalCode)];
                    if (items.length > 0) {
                        return items[0];
                    }
                    throw new HttpErrorResponse({ error: 'not-found', status: 404 });
                }),
            );
    }

    /** V2 */

    autosuggest(query: string, countries: string[], skipCountryCode: string): Observable<HereGeocodeModel[]> {
        const params: Record<string, string> = {
            q: query,
            in: `countryCode:${countries.filter((country) => country !== skipCountryCode).join(',')}`,
            limit: '10',
            show: 'details',
            at: environment.integrations.hereMaps.autosuggest.center.join(','),
            resultTypes: 'locality,street,houseNumber',
        };

        return this.httpClient
            .addHeremapsAutosuggest()
            .get<HereAutocompleteResponseModel>('', { params })
            .pipe(map((response) => response.items.splice(0, 5)));
    }

    autosuggestv2(query: string, countries: string[], skipCountryCode: string): Observable<HereGeocodeModel[]> {
        const params: Record<string, string> = {
            q: query,
            in: `countryCode:${countries.filter((country) => country !== skipCountryCode).join(',')}`,
            limit: '100',
            show: 'details',
            at: environment.integrations.hereMaps.autosuggest.center.join(','),
        };

        return this.httpClient
            .addHeremapsAutosuggest()
            .get<HereAutocompleteResponseModel>('', { params })
            .pipe(map((response) => response.items.filter((item) => ['locality', 'street', 'houseNumber'].includes(item.resultType)).splice(0, 5)));
    }

    nearest(value: NearestValue): Observable<HereGeocodeModel> {
        const params: Record<string, string> = {
            q: value.term,
            in: `countryCode:${value.countryCode}`,
            limit: '10',
            show: 'details',
            at: [value.lat, value.lng].join(','),
            resultTypes: 'street,houseNumber',
        };
        return this.httpClient
            .addHeremapsAutosuggest()
            .skipErrorHandler()
            .get<HereAutocompleteResponseModel>('', { params })
            .pipe(
                map((response) => {
                    const items = [
                        ...response.items
                            .filter((item) => ['street', 'houseNumber'].includes(item.resultType))
                            .filter((item) => item.address.city === value.city)
                            .filter((item) => item.address.postalCode),
                    ];
                    if (items.length > 0) {
                        return items[0];
                    }
                    throw new HttpErrorResponse({ error: 'not-found', status: 404 });
                }),
            );
    }

    nearestv2(value: NearestValue): Observable<HereGeocodeModel> {
        const params: Record<string, string> = {
            q: value.term,
            in: `countryCode:${value.countryCode}`,
            limit: '100',
            show: 'details',
            at: [value.lat, value.lng].join(','),
        };

        return this.httpClient
            .addHeremapsAutosuggest()
            .skipErrorHandler()
            .get<HereAutocompleteResponseModel>('', { params })
            .pipe(
                mergeMap((response) => {
                    const items = [...response.items.filter((item) => item.address.city === value.city).filter((item) => item.address.postalCode)];

                    if (items.length > 0) {
                        return of(items[0]);
                    }

                    return this.reverseGeocode(value.lat, value.lng);
                }),
            );
    }

    reverseGeocode(lat: string, lng: string): Observable<HereGeocodeModel> {
        const params: Record<string, string> = {
            limit: '100',
            at: `${lat},${lng}`,
        };

        return this.httpClient
            .addHeremapsRevGeocode()
            .get<HereAutocompleteResponseModel>('', { params })
            .pipe(
                map((response) => {
                    const addresses = response.items.filter((item) => item.resultType === 'houseNumber' && item.address.postalCode);

                    if (addresses.length > 0) {
                        return addresses[0];
                    } else if (response.items.length > 0) {
                        return response.items[0];
                    }

                    throw new HttpErrorResponse({ error: 'not-found', status: 404 });
                }),
            );
    }
}
